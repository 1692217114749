import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});
const styles = (theme) => ({
  circle: {
    position: "absolute",
    zIndex: "0",
    right: "21%",
    top: "9%",
    width: "115px",
  },
  rect: {
    position: "absolute",
    zIndex: "-2",
    width: "200px",
    left: "40%",
    bottom: "0%",
  },
  previewTitle: {
    textAlign: "center",
    fontSize: "  calc(1.7 * 1rem)",
    fontFamily: "Poppins-Bold",
    [theme.breakpoints.only("xl")]: {
      fontSize: "  calc(2.2 * 1rem)",
    },
  },

  previewDetail: {
    fontSize: " calc(1 * 1rem)",
    textAlign: "center",
    fontFamily: "Poppins-SemiBold",
  },
  previewTitles: {
    textAlign: "center",
    fontSize: "  calc(1.6 * 1rem)",
    fontFamily: "Poppins-Bold",
  },

  logoContainer: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    padding: "10px",
  },

  superfan: {
    textAlign: "center",
    fontFamily: "Poppins-SemiBold",
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },

  logoHeader: {
    textAlign: "center",
    fontSize: "33px",
    fontFamily: "Poppins-Bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
  },
  cloud: {
    padding: "0rem 2rem",
    marginBottom: ".3rem",
    width: "15rem",
    maxWidth: "20rem",
    [theme.breakpoints.only("xl")]: {
      width: "19rem",
    },
  },
  cloud2: {
    padding: "0rem 2rem",
    marginBottom: ".3rem",
    width: "14rem",
    [theme.breakpoints.only("xl")]: {
      width: "19rem",
    },
  },
  cloud3: {
    padding: "0rem 2.2rem",
    marginBottom: ".3rem",
    width: "13rem",
    [theme.breakpoints.only("xl")]: {
      width: "19rem",
    },
  },
  home: {
    width: "155px",
    color: "white",
    borderRadius: "5px",
    filter: "drop-shadow(0px 2px 7px #fea89f)",
    backgroundColor: "#fb260e",
    marginTop: "0.2rem",
    padding: ".8rem 1.5rem",
    fontSize: " 14px",
  },
  buttonDiv: {
    marginLeft: "2rem",
    [breakpoints.down("md")]: {
      marginLeft: "0rem",
    },
  },
  labelLogo: {
    textAlign: "center",
    fontFamily: "Poppins-SemiBold",
    fontSize: "23px",
    color: "#3CB4E4",
    padding: "15px",
  },

  imageLogo: {
    height: "11em",
    [theme.breakpoints.down("sm")]: {
      height: "5em",
    },
  },

  imageLogoCandy: {
    height: "9em",
    [theme.breakpoints.down("sm")]: {
      height: "5em",
    },
  },

  logoImage: {
    height: "1.6em",
    [theme.breakpoints.down("sm")]: {
      height: "0.8em",
    },
  },

  arrow: {
    height: "4em",
    [theme.breakpoints.down("sm")]: {
      height: "2em",
    },
  },

  homeButton: {
    background: "#FB260E",
    fontSize: "24px",
    color: "white",
    fontFamily: "Poppins-Medium",
    textTransform: "capitalize",
    padding: " 5px 59px",
    marginRight: "1rem",
  },

  feedbackButton: {
    background: "#4D9DF8",
    fontSize: "18px",
    color: "white",
    fontFamily: "Poppins-Medium",
    textTransform: "capitalize",
    padding: " 5px 59px",
  },

  backgroundImage: {
    width: "100%",
    height: "90%",
    objectFit: "contain",
    position: "absolute",
    left: "0px",
    top: "85px",
    zIndex: "-1",
  },

  moreInfoButton: {
    background: "#4D9DF8",
    fontSize: "18px",
    fontFamily: "Poppins-Medium",
    textTransform: "capitalize",
    padding: "10px 40px",
  },
});

export default styles;
