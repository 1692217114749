import { SHOW_LOADER } from "./actionTypes";

const initialState = {
  show_loading_page: false,
};

export default function loader(state = initialState, action) {
  switch (action.type) {
    case SHOW_LOADER:
      return { ...state, show_loading_page: action.payload };
    default:
      return { ...state };
  }
}
