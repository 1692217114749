const userService = `/social-dashboard-auth-service/v1/auth`;
const userDataService = "/social-user-service/users";
const userRolesService = "/social-user-service/roles";
const userPermissionsService = "/social-user-service/permissions";
const userRequestService = "/social-dashboard-user-request-service/v1";
const USERS = {
  LOGIN: `${userService}/login`,
  SIGNUP: `${userService}/sign-up`,
  FORGOT_PASSWORD: `${userService}/send-forgot-password-email`,
  RESET_PASSWORD: `${userService}/reset-password`,
};

const USER_DATA = {
  UPDATE: `${userDataService}/update-user`,
  GET: `${userDataService}/get-user-details`,
  GET_ALL: `${userDataService}/list-users`,
  EDIT_USER: `${userDataService}/edit-user`,
  SHARE_URL: `${userDataService}/share-request`,
  EXPORT_DATA: `${userDataService}/export-to-excel`,
  QUERY_EMAIL: `${userDataService}/user-query`,
  GET_TABLES: `${userDataService}/system-tables`,
  EXPORT_FULL_SYSTEM: `${userDataService}/full-system-export`,
};

const USER_PERMISSIONS = {
  GET_ALL_PERMISSIONS: `${userPermissionsService}/list-permissions`,
};

const USER_ROLES = {
  GET_ALL_ROLES: `${userRolesService}/list-roles`,
  CREATE: `${userRolesService}/create`,
  UPDATE: `${userRolesService}/update`,
  DELETE: `${userRolesService}/delete`,
  GET_DETAILS: `${userRolesService}/get-role-detail`,
};

const ADMIN_USER = {
  CREATE: `${userDataService}/create-org-admin`,
  DELETE: `${userDataService}/delete-admins`,
  GET_DETAILS: `${userDataService}/get-admin-details`,
  VERIFY_ADMIN: `${userService}/verify-admin`,
  CREATE_REQUEST: `${userRequestService}/request/request`,
  MARK_REQUEST_AS_BRAND: `${userRequestService}/request/mark-as-favorite`,
  SEND_PRELOAD_EMAIL: `${userRequestService}/request/send-preload-email`,
};

const USER_REQUEST = {
  GET_SOCIALS_PROFILES: `${userRequestService}/scraper/get-social-links`,
  GET_BRAND_REQUESTS: `${userRequestService}/request/brands`,
  ADD_BRAND_TO_FAVORITE: `${userRequestService}/request/add-to-favorites`,
  GET_USER_FAVORITE_BRANDS: `${userRequestService}/request/get-favorites`,
  CREATE: `${userRequestService}/request/create-user-request`,
  GET_REQUESTS_LIST: `${userRequestService}/request/get-user-requests`,
  DELETE_REQUEST: `${userRequestService}/request/delete-request`,
  GET_REQUEST_DETAILS: `${userRequestService}/request/get-request-details`,
  UPDATE_REQUEST: `${userRequestService}/request/update-request`,
  CREATE_REQUEST_AND_SIGNUP: `${userRequestService}/request/create-guest-request`,
  GET_ALL_REQUESTS: `${userRequestService}/request/list-requests`,
  GET_REQUEST_DETAILS_ADMIN: `${userRequestService}/request/request-details`,
  UPLOAD_FILE: `${userRequestService}/request/upload-request-csv`,
  GET_REQUEST_DETAILS_PUBLIC: `${userService}/get-request-details-public`,
  GET_GLOBAL_WEIGHTS: `${userRequestService}/weights/get-weights`,
  UPDATE_GLOBAL_METRICS: `${userRequestService}/weights/update-metrics`,
  UPDATE_GLOBAL_WEIGHTS: `${userRequestService}/weights/update-weights`,
  UPDATE_WEIGHTS: `${userRequestService}/request/update-request-weights`,
  UPDATE_METRICS: `${userRequestService}/request/update-request-metrics`,
  GET_REQUEST_WEIGHTS: `${userRequestService}/request/get-request-weights`,
  GET_BENCHMARKS: `${userRequestService}/benchmarks/get-benchmarks`,
  DELETE_BENCHMARK: `${userRequestService}/benchmarks/delete-benchmark`,
  ADD_BENCHMARK: `${userRequestService}/benchmarks/add-benchmark`,
  UPDATE_BENCHMARK_AVERAGES: `${userRequestService}/benchmarks/update-current-key-social-metrics-averages`,
  GET_BENCHMARK_AVERAGES: `${userRequestService}/benchmarks/get-current-key-social-metrics-averages`,
};

export {
  USERS,
  USER_DATA,
  USER_REQUEST,
  USER_ROLES,
  USER_PERMISSIONS,
  ADMIN_USER,
};
