import React from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { Button, Container } from "@material-ui/core";
import { Link } from "@reach/router";
import circle from "../../assets/images/circle.png";
import Rect from "../../assets/images/Rect.png";
import d1 from "../../assets/images/d1.png";
import d2 from "../../assets/images/d2.png";
import d3 from "../../assets/images/d3.png";
import d4 from "../../assets/images/d4.png";
import moreInfo from "../../assets/images/moreInfo.png";

const ProductPreview = (props) => {
  const { classes, nextScreen, onClick } = props;

  return (
    <>
      <Container
        style={{
          maxWidth: "1500px",
          postion: "relative",
          height: "70vh",
          padding: "0rem 4rem",
        }}
      >
        <img className={classes.circle} src={circle} alt="img" />
        <img className={classes.rect} src={Rect} alt="img" />
        <div
          onClick={onClick}
          style={{
            position: "absolute",
            right: 46,
            top: "14%",
            textDecoration: "underline",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            borderRadius: "10px",
          }}
        >
          <Typography
            varient={2}
            style={{
              fontFamily: "Poppins-Regular",
              fontSize: "1.3rem",
              fontWeight: 400,
            }}
          >
            Exit
          </Typography>
        </div>
        <Grid>
          <Grid className={classes.previewTitle}>WE ARE BUILDING</Grid>
          <Grid className={classes.previewTitle}>SUPERFAN ROI MARTECH</Grid>
          <Grid style={{ padding: "10px 0px 10px" }}>
            <Grid className={classes.previewDetail}>
              We are building the only complete MARTECH platform that will
            </Grid>
            <Grid className={classes.previewDetail}>
              enable brands to identity, build and monetize a brand "cloud" of
            </Grid>
            <Grid className={classes.previewDetail}>
              superfan advocates. And deliver measurable marketing ROI > 275%.
            </Grid>
          </Grid>
          <Grid className={classes.previewTitles}>
            THREE-STEP PROCESS AND PLATFORM TOOLS TO DRIVE SUPERFAN ROI
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ paddingTop: "35px" }}
          >
            <Grid>
              <img className={classes.cloud} src={d1} />
            </Grid>
            <Grid
              justify="center"
              alignItems="center"
              style={{ display: "flex", paddingBottom: "140px" }}
            ></Grid>
            <Grid>
              <img className={classes.cloud2} src={d2} />
            </Grid>
            <Grid
              justify="center"
              alignItems="center"
              style={{ display: "flex", paddingBottom: "140px" }}
            >
              <img src={Arrow} className={classes.arrow} />
            </Grid>
            <Grid>
              <img className={classes.cloud} src={d3} />
            </Grid>

            <Grid style={{ textAlign: "center" }}>
              <img className={classes.cloud3} src={d4} />
              <Grid style={{ paddingTop: "10px" }}>
                <img
                  style={{
                    padding: "0rem 2rem",
                    marginBottom: ".3rem",
                    width: "100%",
                  }}
                  src={moreInfo}
                  onClick={nextScreen}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify="space-between" align="space-between">
          <div className={classes.buttonDiv}>
            <Button
              className={classes.home}
              component={Link}
              to="/"
              variant="primary"
            >
              Home
            </Button>
          </div>
        </Grid>
      </Container>
    </>
  );
};

export default withStyles(styles)(ProductPreview);
