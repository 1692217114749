const styles = (theme) => ({
  appBarContainer: {
    backgroundColor: "#fff",
    color: "#555353",
    flexWrap: "nowrap",
    position: "absolute",
    left: "81%",
  },

  appTitle: {
    fontSize: "18px",
    fontFamily: "Poppins-SemiBold",
  },

  appLogo: {
    width: "300px",
  },

  appBarRight: {
    height: "45px",
    width: "300px",
    display: "flex",
    justifyContent: "center",
  },

  userName: {
    fontSize: "16px",
    fontFamily: "Poppins-Medium",
  },

  status: {
    fontSize: "10px",
    fontFamily: "Poppins-Medium",
    color: "#7B7B7B",
  },

  dropdownIcon: {
    fontSize: "1.8rem",
    fill: "#7B7B7B",
  },
  submitButton: {
    background: "#fb1902",
    letterSpacing: "0.03em",
    padding: "0.64rem 1.14rem",
    border: "none",
    width: "15%",
    height: "40px",
    color: "#fff",
    fontSize: "15px",
    fontFamily: '"Poppins-Medium" !important',
    "&:hover": {
      background: "#fb1902",
    },
  },

  arrow: {
    height: "2em",
    margin: "0 10px",
    [theme.breakpoints.down("md")]: {
      height: "2em",
    },
  },

  arrowTwo: {
    height: "2em",
    transform: "scaleX(-1)",
    margin: "0 10px",
  },

  screenNumber: {
    fontSize: "20px",
    fontFamily: "poppins-semibold",
  },
});

export default styles;
