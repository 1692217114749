import React, { useState, useEffect } from "react";
import { Col, Container, Button, Row } from "react-bootstrap";
import "./styles.scss";
import { navigate } from "@reach/router";
import { Grid, Typography } from "@material-ui/core";
import Footer from "../Footer";
import TopBar from "../../components/admin/dashboard/topbar";
import worldIcon from "../../assets/svgs/world.svg";
import laptop_screen from "../../assets/images/laptop_screen.png";

export default ({ inputValues, handleChange, submit }) => {
  const [profile, setProfile] = useState(null);
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user");
  useEffect(() => {
    if (token && user) {
      setProfile({ ...JSON.parse(user) });
    } else {
      setProfile(null);
    }
  }, [token, user]);

  function logoutUser() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("role");
    localStorage.removeItem("permissions");

    setProfile(null);
  }

  const listText = [
    "How does your brand rate vs. other brands?",
    "How many SUPERFANs do you have now and how engaged are they?",
    "What is their potential to impact your business?",
    "View other brands results in our massive Superfan data base.",
    "Receive three months of brand updates",
  ];

  const list = () =>
    listText.map((text, index) => (
      <li>
        <Typography className="liText">
          {text}
          {index == 4 && <span style={{ color: "red" }}> FREE!.</span>}
        </Typography>
      </li>
    ));

  return (
    <>
      <TopBar />
      <Container className="main-container-landing">
        <Row className="landing-main-row">
          <Col lg={6} md={7} sm={12} className="column1">
            <div className="main-page-content">
              <div className="main-page">
                <Grid>
                  <Typography variant={2} className="main-header">
                    SUPERFAN POTENTIAL ANALYZER
                  </Typography>
                  <br />
                  <Typography variant={1} className="main-paragraph">
                    WHAT’S YOUR BRAND’S Superfan POTENTIAL?
                  </Typography>
                </Grid>
                <Grid style={{ marginTop: 5 }}>
                  <ul>{list()}</ul>
                  <Typography className="button-title">
                    Enter your brand website URL
                  </Typography>
                </Grid>
                <Grid className="main-input-group">
                  <img
                    style={{
                      height: 30,
                      width: 30,
                      alignSelf: "center",
                      marginRight: 3,
                    }}
                    alt="input-icon"
                    src={worldIcon}
                  />
                  <input
                    className="input-group"
                    type="url"
                    autoFocus={true}
                    value={inputValues ? inputValues.url : ""}
                    onChange={handleChange}
                    placeholder="Url"
                    required={true}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        submit();
                      }
                    }}
                  />
                </Grid>

                <Grid className="submit-button-wrapper">
                  <Button
                    className="buttons"
                    variant="outline-primary"
                    onClick={submit}
                    type="submit"
                    style={{
                      backgroundColor: "#fb260e",
                    }}
                  >
                    Try It Now!
                  </Button>
                </Grid>
              </div>
            </div>
          </Col>
          <Col lg={6} md={5} sm={12} className="column-2">
            <div className="image-div show-image">
              <Grid style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <img
                    className="brand-image"
                    src={laptop_screen}
                    alt="Phone"
                  />
                </div>
                <div className="button-Div" style={{}}>
                  <Button
                    className="buttons"
                    style={{
                      backgroundColor: "#78D124",
                      zIndex: 1,
                    }}
                    variant="outline-primary"
                    onClick={() =>
                      navigate("https://www.brandcloud.com/superfan-101 ")
                    }
                  >
                    What is a SUPERFAN?
                  </Button>
                </div>
              </Grid>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};
