import React from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { Button, Container } from "@material-ui/core";
import { Link } from "@reach/router";
import qIcon from "../../assets/svgs/qIcon";
import Tooltip from "@material-ui/core/Tooltip";
import circle from "../../assets/images/circle.png";
import Rect from "../../assets/images/Rect.png";
import d7 from "../../assets/images/d7.png";
import d5 from "../../assets/images/d5.png";
import moreInfo from "../../assets/images/moreInfo.png";

const ProductPreviewTwo = (props) => {
  const { classes, nextScreen, onClick } = props;

  return (
    <Container
      style={{
        maxWidth: "1400px",
        postion: "relative",
        height: "70vh",
        padding: "0rem 4rem",
      }}
    >
      <img className={classes.circle} src={circle} alt="img" />
      <img className={classes.rect} src={Rect} alt="img" />
      <div
        onClick={onClick}
        style={{
          position: "absolute",
          right: 46,
          top: "14%",
          textDecoration: "underline",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          borderRadius: "10px",
        }}
      >
        <Typography
          variant={2}
          style={{
            fontFamily: "Poppins-Regular",
            fontSize: "1.3rem",
            fontWeight: 400,
          }}
        >
          Exit
        </Typography>
      </div>
      <Grid container direction="row" justify="center">
        <img className={classes.cloud2} src={d7} />
      </Grid>
      <Grid style={{ padding: "10px 0" }}>
        <Grid className={classes.previewDetail}>
          Use Social DNA to decode your superfans by
        </Grid>
        <Grid className={classes.previewDetail}>
          identifying, profiling, and tracking them. Use the
        </Grid>
        <Grid className={classes.previewDetail}>
          real time insights and analytics to build and
        </Grid>
        <Grid className={classes.previewDetail}>
          monetize a superfan FORCE.
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-between"
        style={{ paddingTop: "10px" }}
      >
        <Grid style={{ display: "flex" }}>
          <Grid
            justify="center"
            direction="column"
            container
            style={{ padding: "26px 22px" }}
          >
            <Grid className={classes.header} style={{ position: "relative" }}>
              IDENTIFY
              <Tooltip title="Add title" interactive>
                <span className={classes.absolute}>
                  {qIcon("rgb(146, 146, 146)")}
                </span>
              </Tooltip>
            </Grid>
            <Grid className={classes.slogan}>Who are they?</Grid>
          </Grid>
        </Grid>
        <Grid
          justify="center"
          alignItems="center"
          style={{ display: "flex" }}
        ></Grid>
        <Grid style={{ display: "flex" }}>
          <Grid
            justify="center"
            direction="column"
            container
            style={{ padding: "26px 22px" }}
          >
            <Grid className={classes.header} style={{ position: "relative" }}>
              PROFILE{" "}
              <Tooltip title="Add title" interactive>
                <span className={classes.absolute}>
                  {qIcon("rgb(146, 146, 146)")}
                </span>
              </Tooltip>
            </Grid>
            <Grid className={classes.slogan}>What motivates them?</Grid>
          </Grid>
        </Grid>
        <Grid justify="center" alignItems="center" style={{ display: "flex" }}>
          <img src={Arrow} className={classes.arrow} />
        </Grid>
        <Grid style={{ display: "flex" }}>
          <Grid
            justify="center"
            direction="column"
            container
            style={{ padding: "26px 22px" }}
          >
            <Grid className={classes.header} style={{ position: "relative" }}>
              TRACK{" "}
              <Tooltip title="Add title" interactive>
                <span className={classes.absolute1}>
                  {qIcon("rgb(146, 146, 146)")}
                </span>
              </Tooltip>
            </Grid>
            <Grid className={classes.slogan}>How are they performing?</Grid>
          </Grid>
        </Grid>

        <Grid className={classes.smallLogo}>
          <img className={classes.cloud3} src={d5} />
          <Grid style={{ paddingTop: "10px" }}>
            <img
              style={{
                padding: "0rem 2rem",
                marginBottom: ".3rem",
                width: "100%",
              }}
              src={moreInfo}
              onClick={nextScreen}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justify="flex-start"
        align="space-between"
        style={{ padding: "10px 0 0" }}
      >
        <Button
          variant="contained"
          style={{ color: "white" }}
          color="secondary"
          className={classes.homeButton}
          component={Link}
          to="/"
        >
          Home
        </Button>
        <Button
          variant="contained"
          style={{ color: "white" }}
          color="secondary"
          className={classes.feedbackButton}
          component={Link}
          to="/contact-us"
        >
          Feedback
        </Button>
      </Grid>
    </Container>
  );
};

export default withStyles(styles)(ProductPreviewTwo);
