import React, { Component } from "react";
import LandingPageView from "../../components/LandingPageView/landing-page-view";
import { navigate } from "@reach/router";

import { show_loader } from "../../ducks/loader/action";
import { addSocials } from "../../ducks/socials/actions";

import { connect } from "react-redux";
import Loader from "../../components/LoaderView/loader-view";

import { getBrandSocialsAPI } from "../../api/Scrapper";
import { withSnackbar } from "notistack";
import { URL_validator } from "../../utils/validation";

import { createRequestAPI } from "../../api/UserRequest/index";

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
    };
  }

  componentDidMount() {
    this.setState({
      url: this.props.url ? this.props.url : "",
    });
  }

  handleChange(e) {
    this.setState({
      url: e.target.value,
    });
  }

  async submit() {
    if (this.state.url === "") {
      this.props.enqueueSnackbar("URL Required", {
        variant: "error",
      });
      return;
    }

    if (!URL_validator(this.state.url)) {
      this.props.enqueueSnackbar("Invalid URL", {
        variant: "error",
      });
      return;
    }

    if (this.state.url !== this.props.url) {
      this.props.showLoader(true);

      await getBrandSocialsAPI({
        urls: [this.state.url],
      }).then((result) => {
        const role = localStorage.getItem("role");
        if (result.status === 200) {
          if (result["requestDetails"]) {
            if (role) {
              let uuid = result["requestDetails"].uuid;
              this.createRequest(uuid);
              navigate(`/dashboard/analysis/${uuid}`);
              return;
            } else {
              if (result["requestDetails"].publicUuid) {
                localStorage.removeItem("timerSeconds");
                localStorage.setItem("uuid", result["requestDetails"].uuid);
                navigate(
                  `/dashboard/public/${result["requestDetails"].publicUuid}?preload=true`
                );
                return;
              }
            }
          } else {
            const resultSocialLinks =
              result.socialLinks &&
              result.socialLinks.length > 0 &&
              result.socialLinks[0].socialLinks;
            const platformLinks = {};
            Object.keys(resultSocialLinks).map((link) => {
              platformLinks[link] = {
                handle: resultSocialLinks[link],
              };
              if (this.props.requestType === "EDIT") {
                platformLinks[link]["uuid"] =
                  this.props.user_brand.socialLinks[link].uuid;
              }
            });
            const socialLinks = {
              type: "brand",
              url: this.state.url,
              socialLinks: platformLinks,
            };
            if (this.props.requestType === "EDIT") {
              socialLinks["uuid"] = this.props.user_brand.uuid;
            }
            console.log("api 2", socialLinks);
            this.props.addSocials(socialLinks);
            this.props.showLoader(false);
          }

          navigate("/socials");
        } else {
          this.props.showLoader(false);
          this.props.enqueueSnackbar(`${result.message}`, {
            variant: "error",
          });
        }
      });
    } else {
      navigate("/socials");
    }
  }

  async createRequest(uuid) {
    const data = { uuid: uuid };
    await createRequestAPI(data).then((res) => {
      if (res.status === 200) {
        this.props.enqueueSnackbar("Request Submitted Successfully", {
          variant: "success",
        });
      } else {
        this.props.enqueueSnackbar(res.message, {
          variant: "error",
        });
      }
    });
  }

  createJsonData(data) {
    const platformLinks = {};
    data.socialLinks.map((link) => {
      platformLinks[link.platform] = { handle: link.socialLink, id: link.id };
    });
    const socialLinks = {
      type: "brand",
      url: data.url,
      uuid: data.uuid,
      id: data.id,
      socialLinks: platformLinks,
    };
    this.props.addSocials(socialLinks);
    this.props.showLoader(false);
    return socialLinks;
  }

  render() {
    return (
      <>
        {!this.props.show_loader ? (
          <LandingPageView
            inputValues={this.state}
            handleChange={this.handleChange.bind(this)}
            submit={this.submit.bind(this)}
          />
        ) : (
          <Loader />
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addSocials: (data) => dispatch(addSocials(data)),
  showLoader: (data) => dispatch(show_loader(data)),
});

const mapStateToProps = (data) => ({
  show_loader: data.showLoader.show_loading_page,
  url: data.socials.user_brand.url,
  user_brand: data.socials.user_brand,
  requestType: data.history.requestType,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(LandingPage));
