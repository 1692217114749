import React from "react";
import { navigate } from "@reach/router";
import { connect } from "react-redux";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import _ from "lodash";
// * asset
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import qIcon from "../../../assets/svgs/qIcon";
import mainLogo from "../../../assets/svgs/main-logo.svg";
// * Style
import "../../../assets/style/topbar.css";

import ButtonAppBarCollapse from "../../TopBar/ButtonAppBarCollapse";

import StickyHeader from "../../StickyHeader";

class TopBar extends React.Component {
  logoutUser() {
    const role = localStorage.getItem("role");

    // Remove token and user info from the local storage
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("role");
    localStorage.removeItem("permissions");

    //navigate to respective login page
    if (role === "ADMIN") {
      navigate("/admin/login");
    } else if (role === "USER") {
      navigate("/login");
    }
  }

  getQuestionInfo(info, ref, color = "#929292") {
    return (
      <div ref="tooltip">
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip className="tooltip" id="tooltip" style={{ zIndex: 10000 }}>
              {info}
            </Tooltip>
          }
          container={this.refs[ref]}
        >
          <span
            style={{
              marginLeft: ".1rem",
            }}
          >
            {qIcon(color)}
          </span>
        </OverlayTrigger>
      </div>
    );
  }

  newGetQuestionInfo(info, ref, color = "#929292") {
    return (
      <div ref="tooltip">
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip className="tooltip" id="tooltip" style={{ zIndex: 10000 }}>
              BRANDCLOUD&#174; the only social martech platform that enables
              brands to identify, recruit, engage and monetize superfans at
              scale.
            </Tooltip>
          }
          container={this.refs["heading"]}
        >
          <span
            style={{
              marginLeft: ".1rem",
            }}
          >
            {qIcon("#929292")}
          </span>
        </OverlayTrigger>
      </div>
    );
  }
  //  let navigate = useNavigate();
  render() {
    const { currentUser, classes } = this.props;
    const role = localStorage.getItem("role");

    return (
      <div
        className="container-fluid appbar-container"
        style={{
          padding: "0px 0px",
          position: "fixed",
          top: 0,
          zIndex: 100,
          backgroundColor: "white",
          boxShadow:
            "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
        }}
      >
        <div style={{ padding: "5px 115px" }}>
          <div className="row">
            <Grid
              container
              alignItems="center"
              justify="space-evenly"
              className={classes.appBarContainer}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>
                  <ButtonAppBarCollapse className={classes.buttonAppBar}>
                    {" "}
                    <a
                      className={classes.link}
                      href="https://www.brandcloud.com/superfan-101"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Superfan 101
                    </a>{" "}
                    <a
                      href="https://www.brandcloud.com/about"
                      className={classes.link}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      About Us
                    </a>{" "}
                    <a
                      className={classes.link}
                      href="https://www.brandcloud.com/we-are-building-superfan-roi-martech"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Platform Preview
                    </a>{" "}
                    <a
                      className={classes.link}
                      href="https://www.brandcloud.com/results"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Results
                    </a>{" "}
                    <a
                      className={classes.link}
                      href="https://www.brandcloud.com/case-studies"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Case Studies
                    </a>{" "}
                    <a
                      className={classes.link}
                      href="https://www.brandcloud.com/contact"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Contact
                    </a>
                  </ButtonAppBarCollapse>
                </div>
                <a href="/dashboard">
                  <img
                    alt="logo"
                    className={`${classes.appLogo} "page-title"`}
                    src={mainLogo}
                  />
                </a>
                {this.newGetQuestionInfo()}
              </div>
              <div
                className="app-bar-center text-center"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <span
                  className={classes.appTitle}
                  style={{ display: "flex" }}
                  ref="heading"
                >
                  SUPERFAN POTENTIAL ANALYZER
                  {this.getQuestionInfo(
                    "Provides a current snapshot of a brand's current superfan engagement and near term potential for expansion and impact.",
                    "heading"
                  )}
                </span>
              </div>
              {!_.isEmpty(currentUser) ? (
                <div className={classes.appBarRight}>
                  <Grid
                    className="d-flex user-info"
                    style={{ width: "100%", position: "relative" }}
                  >
                    <Grid container style={{ justifyContent: "center" }}>
                      <Grid style={{ alignSelf: "center" }}>
                        <AccountCircleIcon
                          fontSize={"large"}
                          // style={{ fontSize: "3.5rem" }}
                        />
                      </Grid>
                      <Grid style={{ display: "flex" }}>
                        <Grid
                          container
                          direction="column"
                          justify="center"
                          style={{ marginLeft: "5px", lineHeight: 1 }}
                        >
                          <span className={classes.userName}>
                            {currentUser && currentUser.firstName}
                          </span>
                          <span className={classes.status}>Available</span>
                        </Grid>
                      </Grid>
                    </Grid>
                    <span className="d-flex align-items-center">
                      <MoreVertIcon className={classes.dropdownIcon} />
                    </span>
                    <div className="profile-options">
                      <ul className="options-list">
                        {role === "USER" && (
                          <>
                            <li>
                              <a
                                onClick={() => {
                                  navigate("/dashboard/brands");
                                  window.location.reload();
                                }}
                                className="option-link zero-border"
                              >
                                Brands
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={() => navigate("/edit-profile")}
                                className="option-link zero-border"
                              >
                                Edit Profile
                              </a>
                            </li>

                            <li>
                              <a
                                onClick={() => {
                                  navigate("/dashboard");
                                  window.location.reload();
                                }}
                                className="option-link zero-border"
                              >
                                Dashboard
                              </a>
                            </li>
                          </>
                        )}
                        {role === "USER" && (
                          <li>
                            <a
                              className="option-link zero-border"
                              onClick={() => navigate("/contact-us")}
                            >
                              Contact Us
                            </a>
                          </li>
                        )}
                        {role === "ADMIN" && (
                          <>
                            <li>
                              <a
                                onClick={() => navigate("/SocialArea")}
                                className="option-link zero-border"
                              >
                                Go to social Link
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={() => navigate("/send-preload-email")}
                                className="option-link zero-border"
                              >
                                Send Preloaded Email
                              </a>
                            </li>
                          </>
                        )}
                        <li>
                          <a
                            onClick={() => {
                              this.logoutUser();
                            }}
                            className="option-link zero-border"
                          >
                            Logout
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Grid>
                </div>
              ) : (
                <Button
                  onClick={() =>
                    this.props.isPreload
                      ? navigate("/signup")
                      : navigate("/login")
                  }
                  variant="outline-primary"
                  className={classes.submitButton}
                >
                  {this.props.isPreload ? "Register" : "Login"}
                </Button>
              )}
            </Grid>
          </div>
        </div>
        <div
          className={classes.row2}
          style={{ padding: "0px 115px", backgroundColor: "#f6f6f6" }}
        >
          <div className="row">
            <Grid
              xs={12}
              style={{
                display: "flex",
                backgroundColor: "#f6f6f6",
                justifyContent: "space-around",
                padding: "5px 0px",
              }}
            >
              <StickyHeader textColor={"#000"} />
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (data) => {
  return {
    currentUser: data.user.currentUser,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(TopBar));
