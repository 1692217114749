const styles = (theme) => ({
  copyrightText: {
    fontSize: "15px",
    color: "#7B7B7B",
    fontFamily: "Poppins-Bold",
  },

  footerContainer: {
    padding: "20px 60px",

    bottom: "2px",
    backgroundColor: "#F8F8F8",
  },

  contactText: {
    fontFamily: "Poppins-Bold",
    cursor: "pointer",

    color: "#7b7b7b",
    fontSize: "15px",
  },
});

export default styles;
