import React from "react";

export default (color = "#0a2e69") => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0.333 0.667 6.667 6.667"
    width="14px"
    height="14px"
    style={{ verticalAlign: "top" }}
  >
    {" "}
    <path
      paint-order="stroke fill markers"
      fill-rule="evenodd"
      d="M3.666 7.333a3.333 3.333 0 1 1 0-6.666 3.333 3.333 0 0 1 0 6.666zm0-.666a2.667 2.667 0 1 0 0-5.333 2.667 2.667 0 0 0 0 5.333zM3.333 5H4v.667h-.667V5zM4 4.452v.215h-.667v-.5c0-.184.15-.334.333-.334a.5.5 0 1 0-.49-.598l-.654-.13A1.167 1.167 0 1 1 4 4.451z"
    />
  </svg>
);
