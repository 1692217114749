import React from "react";
import _ from "lodash";
// * asset
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
const StickyHeader = (props) => {
  const { classes, textColor } = props;
  return (
    <>
      <Button className={classes.infoHeaderLinks}>
        <a
          href="https://www.brandcloud.com/superfan-101"
          style={{
            textDecoration: "none",
            color: textColor,
          }}
        >
          Superfan 101
        </a>
      </Button>
      <Button className={classes.infoHeaderLinks}>
        <a
          href="https://www.brandcloud.com/about"
          style={{
            textDecoration: "none",
            color: textColor,
          }}
        >
          About Us
        </a>
      </Button>
      <Button className={classes.infoHeaderLinks}>
        <a
          href="https://www.brandcloud.com/we-are-building-superfan-roi-martech"
          style={{
            textDecoration: "none",
            color: textColor,
          }}
        >
          Platform Preview
        </a>
      </Button>
      <Button className={classes.infoHeaderLinks}>
        <a
          href="https://www.brandcloud.com/results"
          style={{
            textDecoration: "none",
            color: textColor,
          }}
        >
          Results
        </a>
      </Button>
      <Button className={classes.infoHeaderLinks}>
        <a
          href="https://www.brandcloud.com/case-studies"
          style={{
            textDecoration: "none",
            color: textColor,
          }}
        >
          Case Studies
        </a>
      </Button>
      <Button className={classes.infoHeaderLinks}>
        <a
          href="https://www.brandcloud.com/contact"
          style={{
            textDecoration: "none",
            color: textColor,
          }}
        >
          Contact
        </a>
      </Button>
    </>
  );
};

export default withStyles(styles)(StickyHeader);
