const styles = (theme) => ({
  appBarContainer: {
    // backgroundColor: "#fff",
    color: "#555353",
    flexWrap: "nowrap",

    // height:"30vh"
  },

  appTitle: {
    fontSize: "18px",
    fontFamily: "Poppins-SemiBold",
  },

  appLogo: {
    width: "200px",
  },

  appBarRight: {
    height: "45px",
    width: "300px",
    display: "flex",
    justifyContent: "center",
  },

  userName: {
    fontSize: "16px",
    fontFamily: "Poppins-Medium",
  },

  status: {
    fontSize: "10px",
    fontFamily: "Poppins-Medium",
    color: "#7B7B7B",
  },

  dropdownIcon: {
    fontSize: "1.8rem",
    fill: "#7B7B7B",
  },
  submitButton: {
    background: "#fb1902",
    letterSpacing: "0.03em",
    padding: "0.64rem 1.14rem",
    border: "none",
    width: "15%",
    height: "40px",
    color: "#fff",
    fontSize: "15px",
    fontFamily: '"Poppins-Medium" !important',
    "&:hover": {
      background: "#fb1902",
    },
  },
  row2: {
    [theme.breakpoints.up("sm")]: {
      display: "visible",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buttonAppBar: {
    [theme.breakpoints.down("md")]: {
      display: "visible",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  buttonBar: {
    [theme.breakpoints.up("sm")]: {
      display: "visible",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  link: {
    fontWeight: 600,
    textTransform: "capitalize",
    fontSize: 18,
    color: "black",
    lineHeight: "1.8em",
    fontFamily: "Poppins-Regular",
    padding: "1rem",
    paddingLeft: "2rem",
    textAlign: "left",
  },
});

export default styles;
