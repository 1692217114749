import React from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { Container, Button } from "@material-ui/core";
import Music from "../../assets/images/musi.png";
import { Link } from "@reach/router";
import qIcon from "../../assets/svgs/qIcon";
import Tooltip from "@material-ui/core/Tooltip";
import circle from "../../assets/images/circle.png";
import Rect from "../../assets/images/Rect.png";
import d5 from "../../assets/images/d5.png";
import recruit from "../../assets/images/recruit.png";
import d6 from "../../assets/images/d6.png";
import moreInfo from "../../assets/images/moreInfo.png";

const ProductPreviewThree = (props) => {
  const { classes, nextScreen, onClick } = props;
  return (
    <Container
      style={{ maxWidth: "1400px", postion: "relative", height: "70vh" }}
    >
      <img className={classes.circle} src={circle} alt="img" />
      <img className={classes.rect} src={Rect} alt="img" />
      <div
        onClick={onClick}
        style={{
          position: "absolute",
          right: 46,
          top: "14%",
          textDecoration: "underline",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          borderRadius: "10px",
        }}
      >
        <Typography
          varient={2}
          style={{
            fontFamily: "Poppins-Regular",
            fontSize: "1.3rem",
            fontWeight: 400,
          }}
        >
          Exit
        </Typography>
      </div>
      <Grid container direction="row" justify="center">
        <img className={classes.cloud2} src={d5} />
      </Grid>

      <Grid style={{ padding: "10px 0" }}>
        <Grid className={classes.previewDetail}>
          Build a powerful superfan force by recruiting,{" "}
        </Grid>
        <Grid className={classes.previewDetail}>
          progressively engaging and training them to be skilled
        </Grid>
        <Grid className={classes.previewDetail}>brand advocates.</Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-between"
        style={{ padding: "32px 0 5" }}
      >
        <Grid style={{ display: "flex" }}>
          <img src={recruit} alt="img" className={classes.iconLogos} />
          <Grid
            justify="center"
            direction="column"
            container
            style={{ padding: "26px 22px" }}
          >
            <Grid className={classes.header} style={{ position: "relative" }}>
              RECRUIT{" "}
              <Tooltip title="Add title" interactive>
                <span className={classes.absolute}>
                  {qIcon("rgb(146, 146, 146)")}
                </span>
              </Tooltip>
            </Grid>
            <Grid className={classes.slogan}>A starter force of superfans</Grid>
          </Grid>
        </Grid>
        <Grid
          justify="center"
          alignItems="center"
          style={{ display: "flex" }}
        ></Grid>
        <Grid style={{ display: "flex" }}>
          <Grid style={{ marginTop: "15px" }}>
            <Grid className={classes.vip}>VIP</Grid>
          </Grid>
          <Grid
            justify="center"
            direction="column"
            container
            style={{ padding: "26px 22px" }}
          >
            <Grid className={classes.header} style={{ position: "relative" }}>
              ENGAGE{" "}
              <Tooltip title="Add title" interactive>
                <span className={classes.absolute}>
                  {qIcon("rgb(146, 146, 146)")}
                </span>
              </Tooltip>
            </Grid>
            <Grid className={classes.slogan}>
              Progressively Engage & Recognize
            </Grid>
          </Grid>
        </Grid>
        <Grid justify="center" alignItems="center" style={{ display: "flex" }}>
          <img src={Arrow} className={classes.arrow} />
        </Grid>
        <Grid style={{ display: "flex" }}>
          <img src={Music} className={classes.iconTrack} />
          <Grid
            justify="center"
            direction="column"
            container
            style={{ padding: "26px 22px" }}
          >
            <Grid className={classes.header} style={{ position: "relative" }}>
              ADVOCACY{" "}
              <Tooltip title="Add title" interactive>
                <span className={classes.absolute1}>
                  {qIcon("rgb(146, 146, 146)")}
                </span>
              </Tooltip>
            </Grid>
            <Grid className={classes.slogan}>
              Train them to be Brand ADVOCATES
            </Grid>
          </Grid>
        </Grid>

        <Grid className={classes.smallLogo}>
          <img className={classes.cloud3} src={d6} />
          <Grid style={{ paddingTop: "10px" }}>
            <img
              style={{
                padding: "0rem 2rem",
                marginBottom: ".3rem",
                width: "100%",
              }}
              src={moreInfo}
              onClick={nextScreen}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justify="flex-start"
        align="space-between"
        style={{ padding: "5px 0 0" }}
      >
        <Button
          variant="contained"
          style={{ color: "white" }}
          color="secondary"
          className={classes.homeButton}
          component={Link}
          to="/"
        >
          Home
        </Button>
        <Button
          variant="contained"
          style={{ color: "white" }}
          color="secondary"
          className={classes.feedbackButton}
          component={Link}
          to="/contact-us"
        >
          Feedback
        </Button>
      </Grid>
    </Container>
  );
};

export default withStyles(styles)(ProductPreviewThree);
