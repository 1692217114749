const styles = (theme) => ({
  infoHeaderLinks: {
    fontFamily: "ClanOT-Narrow Bold",
    textTransform: "capitalize",
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
});

export default styles;
