import React, { Suspense, useEffect } from "react";
import "./App.css";
import Router from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import { SnackbarProvider } from "notistack";
import ReactGA from "react-ga";
import { LocationProvider, Location } from "@reach/router";

ReactGA.initialize(process.env.REACT_APP_GA_CONFIG);

function App() {
  const handleLocations = (loc) => {
    console.log("test", loc.location.pathname + loc.location.search);
    ReactGA.pageview(loc.location.pathname + loc.location.search);
  };

  return (
    <div className="App">
      <SnackbarProvider maxSnack={3}>
        <Suspense fallback={<div />}>
          <LocationProvider>
            <Location>{(location) => handleLocations(location)}</Location>

            <Router />
          </LocationProvider>
        </Suspense>
      </SnackbarProvider>
    </div>
  );
}

export default App;
