import React, { useState } from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Modal } from "@material-ui/core";
import { Button, Container } from "@material-ui/core";
import Dashboard from "../../assets/images/Dashboard5.png";
import { Link } from "@reach/router";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import circle from "../../assets/images/circle.png";
import moreinfo from "../../assets/images/moreInfo.png";

const ProductPreviewSix = (props) => {
  const { classes, onClick, nextscreen } = props;
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Container style={{ maxWidth: "1400px", postion: "relative" }}>
      <div
        onClick={onClick}
        style={{
          position: "absolute",
          right: 46,
          top: "14%",
          textDecoration: "underline",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          borderRadius: "10px",
        }}
      >
        <Typography
          varient={2}
          style={{
            fontFamily: "Poppins-Regular",
            fontSize: "1.3rem",
            fontWeight: 400,
          }}
        >
          Exit
        </Typography>
      </div>
      <img className={classes.circle} src={circle} alt="img" />
      <Grid
        container
        direction="row"
        justify="center"
        style={{ padding: "26px 0" }}
      >
        <Grid>
          <img src={Candy} style={{ height: "8em" }} />
          <Grid className={classes.logoContainer}></Grid>
          <Grid className={classes.superfan}>SUPERFAN</Grid>
          <Grid className={classes.logoHeader}>SOCIAL DNA</Grid>
        </Grid>
        <Grid className={classes.logoLabel}>
          <Grid>Social DNA</Grid>
          <Grid>Recommendation Engine</Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={2}>
          <Grid
            container
            direction="column"
            justify="flex-end"
            style={{ height: "100%" }}
          >
            <Grid>
              <Grid style={{ padding: "0px 25px" }}>
                <Grid className={classes.logoContainer}>
                  <img src={Candy} style={{ height: "8em" }} />
                </Grid>
                <Grid className={classes.logoContainer}></Grid>
                <Grid className={classes.superfan}>SUPERFAN</Grid>
                <Grid className={classes.logoHeaderTwo}>SOCIAL DNA</Grid>
                <Grid
                  className={classes.logoHeaderTwo}
                  style={{ padding: "10px 0" }}
                >
                  Provides strategic recommendations based on current data.
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8} style={{ padding: "10px", position: "relative" }}>
          <Grid onClick={openModal}>
            <img src={Dashboard} className={classes.dashbaordImage} />
          </Grid>
        </Grid>
        <Grid
          item
          xs={2}
          style={{ paddingTop: "10px", alignItems: "center", display: "flex" }}
        >
          <img
            style={{
              padding: "0rem 2rem",
              marginBottom: ".3rem",
              width: "100%",
            }}
            src={moreinfo}
            onClick={nextscreen}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justify="end"
        align="space-between"
        style={{ padding: "26px 0" }}
      >
        <Button
          variant="contained"
          style={{ color: "white" }}
          color="secondary"
          className={classes.homeButton}
          component={Link}
          to="/"
        >
          Home
        </Button>
        <Button
          variant="contained"
          style={{ color: "white" }}
          color="secondary"
          className={classes.feedbackButton}
          component={Link}
          to="/contact-us"
        >
          Feedback
        </Button>
      </Grid>
      <Modal open={open} onClose={closeModal}>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{
            padding: "50px 0",
            overflowY: "auto",
            height: "100%",
            alignItems: "flex-start",
            flexDirection: "row-reverse",
          }}
        >
          <IconButton
            aria-label="close"
            style={{ color: "white", marginLeft: "1rem" }}
            onClick={closeModal}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
          <img src={Dashboard} style={{ width: "90%" }} />
        </Grid>
      </Modal>
    </Container>
  );
};

export default withStyles(styles)(ProductPreviewSix);
