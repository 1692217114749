import validator from "validator";
export const PHONE_NUMBER_REGEX = {
  regex: /^[+]\d{1}[0-9]{3}[0-9]{3}[0-9]{4}$/,
  regexMessage: "Invalid Phone Number (+123456789101)",
};

export const EMAIL_REGEX = {
  regex:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  regexMessage: "Invalid email address!",
};

const URL_REGEX =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

export function URL_validator(url) {
  if (URL_REGEX.test(url)) {
    return true;
  } else {
    return false;
  }
}

export function userCreationValidation(key, values) {
  let error = [];
  if (!values.firstName)
    error.push({
      key: "firstName",
      error: "Field Cannot be Empty!",
    });

  if (values.firstName && values.firstName.length < 5) {
    error.push({
      key: "firstName",
      error: "Field length should not be lesser than 5!",
    });
  }

  if (!values.lastName)
    error.push({
      key: "lastName",
      error: "Field Cannot be Empty!",
    });

  if (values.lastName && values.lastName.length < 5) {
    error.push({
      key: "lastName",
      error: "Field length should not be lesser than 5!",
    });
  }

  if (key !== "update" && !values.password)
    error.push({
      key: "password",
      error: "Field Cannot be Empty!",
    });

  if (values.password && values.password.length < 6) {
    error.push({
      key: "password",
      error: "Password length should not be lesser than 6!",
    });
  }

  if (!values.email)
    error.push({
      key: "email",
      error: "Field Cannot be Empty!",
    });

  if (values.email) {
    let test = EMAIL_REGEX.regex.test(values.email);
    if (!test)
      error.push({
        key: "email",
        error: EMAIL_REGEX.regexMessage,
      });
  }

  return error;
}
