import React, { lazy, Suspense, Component } from "react";
import { Router, useLocation } from "@reach/router";
import { connect } from "react-redux";
import { setLoggedIn, setUser } from "../ducks/user/actions";
import checkPermissions from "../utils/checkPermissions";
import ProductPreviewScreen from "../containers/ProductPreviewScreen";

const Sfpa = lazy(() => import("../containers/SFPA"));

const LandingPage = lazy(() =>
  import("../containers/LandingPage/landing-page")
);

const AdminLogin = lazy(() => import("../containers/Admin/Login/login"));

const LoginPage = lazy(() => import("../containers/Login/login"));
const SignUpPage = lazy(() => import("../containers/Signup/signup"));
const DemoPage = lazy(() => import("../containers/Details"));
const SocialLinksPage = lazy(() =>
  import("../containers/SocialLinksPage/social-links-page")
);
const CompetitorsPage = lazy(() =>
  import("../containers/CompetitorsPage/competitor")
);
const ResetPasswordPage = lazy(() =>
  import("../containers/ResetPasswordPage/reset-password")
);
const ForgotPasswordPage = lazy(() =>
  import("../containers/ForgotPasswordPage/forgot-password-page")
);
const Admin = lazy(() => import("../containers/Organization/User/index"));
const Dashboard = lazy(() => import("../components/admin/dashboard/Dashboard"));
const AdminSocialLink = lazy(() =>
  import("../containers/AdminSocialLink/AdminSocialLink")
);
const SocialLink = lazy(() =>
  import("../containers/Admin/Sociallink/SocialLink")
);
const SocialArea = lazy(() =>
  import("../containers/Admin/SocialArea/SocialArea")
);
const PreloadEmail = lazy(() =>
  import("../containers/Admin/PreloadEmail/PreloadEmail")
);
const AnalyticsDashboard = lazy(() =>
  import("../containers/AnalysisPage/analysis-page")
);

const RequestListPage = lazy(() =>
  import("../containers/RequestsListPage/index")
);

const BrandDashboardList = lazy(() =>
  import("../containers/BrandDashboardListPage/index")
);

const NotFoundPage = lazy(() =>
  import("../components/DefaultPage/default-page")
);

const ContactUsPage = lazy(() =>
  import("../containers/ContactUsPage/contact-us-page")
);

const PageLoadingView = lazy(() =>
  import("../components/PageLoaderView/page-loader-view")
);

const EmailVerificationView = lazy(() =>
  import("../components/EmailVerificationView/email-verification-view")
);

const EmailVerifiedView = lazy(() =>
  import("../components/EmailVerifiedView/email-verified-view")
);

const EditProfilePage = lazy(() =>
  import("../containers/UserProfilePage/user-profile-page")
);

const RoleListPage = lazy(() =>
  import("../containers/UserRolesListPage/user-roles-list-page")
);

const AdminUsers = lazy(() =>
  import("../containers/Organization/AdminUser/index")
);

const Benchmark = lazy(() => import("../containers/BenchmarkPage/benchmark"));
const WhatIsSuperFan = lazy(() => import("../containers/WhatIsSuperFan"));
const SuperfanContactUs = lazy(() => import("../containers/SuperfanContactUs"));
const ExportPage = lazy(() => import("../containers/ExportsPage/exports-page"));
const Contact = lazy(() => import("../containers/SFPA/Contactus"));
const InfoContact = lazy(() => import("../containers/infoContact"));

const CaseStudy = lazy(() => import("../containers/SFPA/CaseStudy/index"));
const SuperExpert = lazy(() => import("../containers/SFPA/Expert/index"));
const Layout = lazy(() => import("../components/Layout/index"));

const Person = lazy(() => import("../containers/SFPA/Person/Index"));
const Video = lazy(() => import("../containers/SFPA/Video"));

class Routes extends Component {
  componentDidMount() {
    let token = localStorage.getItem("token");
    let user = localStorage.getItem("user");

    if (window.location.href.includes("/dashboard/brands")) {
      this.setState({ selection: true });
    }
    if (token && user) {
      this.props.setUser(JSON.parse(user));
      this.props.setLoggedIn(true);
    } else {
      this.props.setLoggedIn(false);
    }
  }

  render() {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    return (
      <Suspense fallback={<PageLoadingView />}>
        <Router>
          <SuperfanContactUs path="/super-fan-contact-us" />
          <Layout path="/layout">
            <WhatIsSuperFan path="/what-is-super-fan" />
            <Sfpa path="/sfpa" />
            <InfoContact path="/info-contact" />
            <SuperExpert path="/expert" />
            <CaseStudy path="/case" />
            <Person path="/person" />
            <ProductPreviewScreen path="/product-preview" />
            <Contact path="/contact" />
          </Layout>
          {role !== "ADMIN" && (
            <>
              <LandingPage path="/" />
              <DemoPage path="/details" />
              <Video path="/video" />

              <SignUpPage path="/signup" />
              {console.log(
                "this.state.selection gg",
                this.state && this.state.selection
              )}
              <LoginPage
                default
                path="/login"
                state={{ selection: this.state?.selection }}
              />
              <SocialLinksPage path="/socials" />
              <ContactUsPage path="/contact-us" />
            </>
          )}
          <EmailVerificationView path="/verification-email" />
          <EmailVerifiedView path="/success" />
          <EditProfilePage path="/edit-profile" />
          <ForgotPasswordPage path="/forgot-password" />
          <ResetPasswordPage exact path="/reset-password/:reset_token" />
          <ResetPasswordPage
            exact
            isAdmin={true}
            path="/set-password/:set_token"
          />
          <Dashboard role={role} path="/dashboard">
            <AnalyticsDashboard path="/analysis/:request_id" />
            <AnalyticsDashboard path="/analysis/" />
            <AnalyticsDashboard
              public={true}
              path="/public/:public_request_uuid"
            />
            {console.log(
              "this.state.selection",
              this.state && this.state.selection
            )}
            {token && this.props.isLoggedIn && role === "USER" ? (
              !this.state?.selection ? (
                <RequestListPage default path="/" />
              ) : (
                <BrandDashboardList default path="/brands" />
              )
            ) : (
              token &&
              this.props.isLoggedIn &&
              role === "ADMIN" && (
                <>
                  {checkPermissions("LIST_REQUESTS") && (
                    <RequestListPage path="/requests" />
                  )}
                  {checkPermissions("LIST_USERS") && <Admin default path="/" />}
                  {checkPermissions("READ_ROLE") && (
                    <RoleListPage path="/roles" exact />
                  )}
                  {checkPermissions("LIST_USERS") && (
                    <AdminUsers path="/admin-users" exact />
                  )}
                  {checkPermissions("EXPORT_DATA") && (
                    <ExportPage path="/export-data" exact />
                  )}
                  {checkPermissions("ADD_BENCHMARK") && (
                    <Benchmark path="/benchmark" exact />
                  )}
                </>
              )
            )}
          </Dashboard>
          <AdminLogin
            path="/admin/login"
            default={role === "ADMIN" ? true : false}
            exact
          />
          {token && this.props.isLoggedIn && role === "ADMIN" && (
            <>
              <SocialArea path="SocialArea" exact />
              <SocialLink path="/Link" exact />
              <AdminSocialLink path="/Link/socials" />
              <PreloadEmail path="/send-preload-email" />
            </>
          )}
          <NotFoundPage path="/not-found" exact />
        </Router>
      </Suspense>
    );
  }
}

const mapStateToProps = (data) => {
  return {
    isLoggedIn: data.user.loggedIn,
    role: localStorage.getItem("role"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setLoggedIn: (data) => dispatch(setLoggedIn(data)),
  setUser: (data) => dispatch(setUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
