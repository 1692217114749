const styles = (theme) => ({
  previewTitle: {
    textAlign: "center",
    padding: "0 280px",
    fontSize: "25px",
    fontFamily: "Poppins-Bold",
  },
  circle: {
    position: "absolute",
    zIndex: "0",
    right: "21%",
    top: "9%",
    width: "115px",
  },
  previewDetail: {
    fontSize: "20px",
    textAlign: "center",
    fontFamily: "Poppins-SemiBold",
  },

  logoContainer: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    padding: "10px",
  },

  superfan: {
    textAlign: "center",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
  },

  logoHeader: {
    textAlign: "center",
    fontSize: "25px",
    fontFamily: "Poppins-Bold",
  },

  logoHeaderTwo: {
    textAlign: "center",
    fontSize: "30px",
    fontFamily: "Poppins-Bold",
  },

  labelLogo: {
    textAlign: "center",
    fontFamily: "Poppins-SemiBold",
    fontSize: "23px",
    color: "#3CB4E4",
    padding: "15px",
  },

  smallLogo: {
    marginTop: "-32px",
    [theme.breakpoints.down("md")]: {
      marginTop: "0",
    },
  },

  iconLogos: {
    height: "6em",
    [theme.breakpoints.down("md")]: {
      height: "6em",
    },
  },

  iconLogoSmall: {
    height: "6em",
    [theme.breakpoints.down("md")]: {
      height: "4em",
    },
  },

  iconTrack: {
    height: "5em",
    marginTop: "50px",
  },

  arrow: {
    height: "3em",
  },

  header: {
    fontFamily: "Poppins-SemiBold",
    fontSize: "20px",
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
    },
  },

  slogan: {
    fontFamily: "Poppins-SemiBold",
    fontSize: "12px",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
    },
  },

  logoLabel: {
    alignSelf: "center",
    fontFamily: "Poppins-SemiBold",
    fontSize: "35px",
    padding: "0 24px",
    textAlign: "center",
  },

  dashbaordImage: {
    width: "100%",
  },

  fall: {
    fontFamily: "Poppins-Bold",
    fontSize: "20px",
  },

  arrowTwo: {
    height: "2.2em",
    transform: "scaleX(-1)",
  },

  points: {
    fontFamily: "Poppins-SemiBold",
    fontSize: "11px",
  },

  homeButton: {
    background: "#FB260E",
    fontSize: "24px",
    fontFamily: "Poppins-Medium",
    textTransform: "capitalize",
    padding: " 5px 59px",
    marginRight: "1rem",
  },

  feedbackButton: {
    background: "#4D9DF8",
    fontSize: "24px",
    fontFamily: "Poppins-Medium",
    textTransform: "capitalize",
    padding: " 5px 59px",
  },

  backgroundImage: {
    width: "100%",
    height: "90%",
    objectFit: "contain",
    position: "absolute",
    left: "0px",
    top: "85px",
    zIndex: "-1",
  },

  modalDashbaordImage: {
    width: "75%",
  },
  GotoButton: {
    background: "rgb(77, 157, 248)",
    fontSize: "16px",
    color: "white",
    fontFamily: "Poppins-Medium",
    textTransform: "capitalize",
    padding: "10px 14px",
  },
});

export default styles;
