import axios from "axios";
import SERVER_URL from "../config/config";

import store from "../store";

export const getJwt = () => {
  let token = localStorage.getItem("token");
  return token;
};

const getOptions = (isFile) => {
  let options = {
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
  };
  if (isFile) {
    options["headers"]["Content-Type"] = "multipart/form-data";
    // options.responseType = "blob";
  }
  return options;
};

const prepareUrl = (api, isCompleteURL) => {
  console.log(`${SERVER_URL}${api}`);
  if (isCompleteURL) return api;
  return `${SERVER_URL}${api}`;
};

const wrapper = {
  get: (api, isCompleteURL = false, isFile = false) =>
    axios.get(prepareUrl(api, isCompleteURL), getOptions(isFile)),
  post: (api, formData = {}, isFile = false) =>
    axios.post(prepareUrl(api), formData, getOptions(isFile)),
  put: (api, formData = {}) =>
    axios.put(prepareUrl(api), formData, getOptions()),
  delete: (api) => axios.delete(prepareUrl(api), getOptions()),
};

export default wrapper;
