import axios from "axios";
// * Server URL
import SERVER_URL from "../../config/config";

// * Token
import { TOKEN } from "../../config/index";

import { USER_REQUEST } from "../../config/apiRoutes";

// * Options for api call
let options = {
  headers: {
    Authorization: `Bearer ${TOKEN}`,
  },
};

export const getBrandSocialsAPI = async (data) => {
  try {
    let result = await axios.post(
      `${SERVER_URL}${USER_REQUEST.GET_SOCIALS_PROFILES}`,
      data,
      options
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};
