import { combineReducers } from "redux";

// * reducers
import user from "./user/reducer";
import socials from "./socials/reducer";
import showLoader from "./loader/reducer";
import competitors from "./competitors/reducer";
import history from "./history/reducer";

const rootReducer = combineReducers({
  user,
  socials,
  showLoader,
  competitors,
  history,
});

export default rootReducer;
