import React, { useState } from "react";
import ReactDOM from "react-dom";
import "react-responsive-modal/styles.css";
import ResponsiveModal from "react-responsive-modal";

const Tabsec = (props) => {
  const { setModalVisible, modalVisible, children } = props;
  const styles = {
    modal: {
      backgroundColor: "rgba(255, 255, 255,.8)",
      boxShadow: "none",
      display: "flex",
      overflow: "none",
      width: "100%",
      padding: "0",
      margin: "0",
      height: "100%",
      minWidth: "100%",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    overlay: {
      backgroundColor: "#1cccc",
      padding: 0,
    },
    closeIcon: {
      fill: "black",
    },
  };
  return (
    <div>
      <ResponsiveModal
        open={modalVisible}
        onClose={() => setModalVisible(false)}
        styles={styles}
        animationDuration={1000}
        focusTrapped={true}
        closeIconSize={40}
        showCloseIcon={true}
      >
        {children}
      </ResponsiveModal>
    </div>
  );
};
export default Tabsec;
