import React from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { Button, Container } from "@material-ui/core";
import { Link } from "@reach/router";
import circle from "../../assets/images/circle.png";
import dashbrd from "../../assets/images/dashbrd.png";

const ProductPreviewFive = (props) => {
  const { classes, onClick } = props;
  return (
    <Container style={{ maxWidth: "1400px", postion: "relative" }}>
      <div
        onClick={onClick}
        style={{
          position: "absolute",
          right: 46,
          top: "14%",
          textDecoration: "underline",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          borderRadius: "10px",
        }}
      >
        <Typography
          varient={2}
          style={{
            fontFamily: "Poppins-Regular",
            fontSize: "1.3rem",
            fontWeight: 400,
          }}
        >
          Exit
        </Typography>
      </div>
      <img className={classes.circle} src={circle} alt="img" />

      <Grid container>
        <img className={classes.img} src={dashbrd} alt="img" />
      </Grid>
      <Grid
        container
        justify="flex-start"
        align="space-between"
        style={{ padding: "26px 0" }}
      >
        <Button
          variant="contained"
          style={{ color: "white" }}
          color="secondary"
          className={classes.homeButton}
          component={Link}
          to="/"
        >
          Home
        </Button>
        <Button
          variant="contained"
          style={{ color: "white" }}
          color="secondary"
          className={classes.feedbackButton}
          component={Link}
          to="/contact-us"
        >
          Feedback
        </Button>
      </Grid>
    </Container>
  );
};

export default withStyles(styles)(ProductPreviewFive);
