import React, { useState, useEffect } from "react";
import "./loader-view.css";
import { makeStyles } from "@material-ui/core/styles";
import loader from "../../assets/images/new_loader.png";
import { Typography } from "@material-ui/core";
import mainLogo from "../../assets/svgs/main-logo.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "40%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    position: "relative",
    left: "30%",
    top: "50%",
    marginTop: "28px",
    borderRadius: "50%",
    height: "20%",
    marginBottom: "20px",
  },
}));

export default () => {
  const classes = useStyles();

  const [startLoading, handleLoading] = useState(0);

  return (
    <div className="loader-main">
      <div className="text-center">
        <div className="logo">
          <img alt="logo" src={mainLogo} />
        </div>
        <Typography variant={2} className="main-header">
          SUPERFAN POTENTIAL ANALYZER
        </Typography>
        <br />
        <Typography variant={2} className="main-paragraph">
          CONNECTING TO YOUR SOCIAL PROFILES
        </Typography>

        <div className="loading-bar">
          <div className="ptest" style={{ backgroundColor: "#e9e9e9" }}>
            <div className="test" />
          </div>
          <img
            src={loader}
            style={{
              position: "relative",
              height: "9em",
              objectFit: "contain",
              // top: 10,
            }}
          />
        </div>
      </div>
    </div>
  );
};
