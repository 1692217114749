import axios from "axios";
// * Server URL
import SERVER_URL from "../../config/config";

// * Token
import { getJwt } from "../axios";

import { USER_REQUEST, ADMIN_USER } from "../../config/apiRoutes";

// * Options for api call
let options = () => {
  return {
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
  };
};

export const createRequestAPI = async (data) => {
  try {
    let result = await axios.post(
      `${SERVER_URL}${USER_REQUEST.CREATE}`,
      data,
      options()
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

export const createRequestAPIByAdmin = async (data) => {
  try {
    let result = await axios.post(
      `${SERVER_URL}${ADMIN_USER.CREATE_REQUEST}`,
      data,
      options()
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

export const markRequestAsBrand = async (data) => {
  console.log("data name: ", data);
  try {
    let result = await axios.put(
      `${SERVER_URL}${ADMIN_USER.MARK_REQUEST_AS_BRAND}`,
      data,
      options()
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

export const getRequestsAPI = async (
  offset = 0,
  limit = 20,
  orderBy = "",
  orderType = "asc",
  queryStr = ""
) => {
  try {
    let result = await axios.get(
      `${SERVER_URL}${USER_REQUEST.GET_REQUESTS_LIST}?page=${offset}&pageSize=${limit}&orderType=${orderType}&orderBy=${orderBy}&queryStr=${queryStr}`,
      options()
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

export const getBrandRequestsAPI = async () => {
  try {
    let result = await axios.get(
      `${SERVER_URL}${USER_REQUEST.GET_BRAND_REQUESTS}`,
      options()
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

export const addBrandToFavorite = async (data) => {
  try {
    let result = await axios.post(
      `${SERVER_URL}${USER_REQUEST.ADD_BRAND_TO_FAVORITE}`,
      data,
      options()
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

export const sendPreloadEmail = async (data) => {
  console.log("email:: ", data);
  try {
    let result = await axios.post(
      `${SERVER_URL}${ADMIN_USER.SEND_PRELOAD_EMAIL}`,
      data,
      options()
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

export const getUserFavoriteBrands = async () => {
  try {
    let result = await axios.get(
      `${SERVER_URL}${USER_REQUEST.GET_USER_FAVORITE_BRANDS}`,
      options()
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

export const deleteRequestsAPI = async (uuid) => {
  try {
    let result = await axios.delete(
      `${SERVER_URL}${USER_REQUEST.DELETE_REQUEST}/${uuid}`,
      options()
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

export const getRequestDetailsAPI = async (uuid) => {
  try {
    let result = await axios.get(
      `${SERVER_URL}${USER_REQUEST.GET_REQUEST_DETAILS}/${uuid}`,
      options()
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

export const updateRequest = async (uuid, data) => {
  try {
    let result = await axios.put(
      `${SERVER_URL}${USER_REQUEST.UPDATE_REQUEST}/${uuid}`,
      data,
      options()
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

export const createRequestAndSignupAPI = async (data) => {
  try {
    let result = await axios.post(
      `${SERVER_URL}${USER_REQUEST.CREATE_REQUEST_AND_SIGNUP}`,
      data,
      options()
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

export const getAllRequests = async (
  offset = 0,
  limit = 20,
  orderBy = "",
  orderType = "asc",
  queryStr = "",
  filter = null
) => {
  try {
    let result = await axios.get(
      `${SERVER_URL}${USER_REQUEST.GET_ALL_REQUESTS}?page=${offset}&pageSize=${limit}&orderType=${orderType}&orderBy=${orderBy}&queryStr=${queryStr}&${filter}`,
      options()
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

export const getRequestDetailsForAdminAPI = async (uuid) => {
  try {
    let result = await axios.get(
      `${SERVER_URL}${USER_REQUEST.GET_REQUEST_DETAILS_ADMIN}/${uuid}`,
      options()
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

export const uploadFile = async (uuid, file) => {
  const options = {
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
  };
  options["headers"]["Content-Type"] = "multipart/form-data";
  const data = new FormData();
  data.set("file", file);
  try {
    let result = await axios.post(
      `${SERVER_URL}${USER_REQUEST.UPLOAD_FILE}/${uuid}`,
      data,
      options
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

export const getRequestDetailsPublicAPI = async (uuid) => {
  try {
    let result = await axios.get(
      `${SERVER_URL}${USER_REQUEST.GET_REQUEST_DETAILS_PUBLIC}/${uuid}`,
      options()
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

/** Global Weights API **/

export const getGlobalWeights = async () => {
  try {
    let result = await axios.get(
      `${SERVER_URL}${USER_REQUEST.GET_GLOBAL_WEIGHTS}`,
      options()
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

export const updateGlobalWeights = async (data) => {
  try {
    let result = await axios.put(
      `${SERVER_URL}${USER_REQUEST.UPDATE_GLOBAL_WEIGHTS}`,
      data,
      options()
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

export const updateGlobalMetrics = async (data) => {
  try {
    let result = await axios.put(
      `${SERVER_URL}${USER_REQUEST.UPDATE_GLOBAL_METRICS}`,
      data,
      options()
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

/** Per Request Weights API **/

export const updateRequestWeights = async (uuid, data) => {
  try {
    let result = await axios.post(
      `${SERVER_URL}${USER_REQUEST.UPDATE_WEIGHTS}/${uuid}`,
      data,
      options()
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

export const updateRequestMetrics = async (uuid, data) => {
  try {
    let result = await axios.post(
      `${SERVER_URL}${USER_REQUEST.UPDATE_METRICS}/${uuid}`,
      data,
      options()
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

export const getWeights = async (uuid) => {
  try {
    let result = await axios.get(
      `${SERVER_URL}${USER_REQUEST.GET_REQUEST_WEIGHTS}/${uuid}`,
      options()
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

/* Benchmark APIs */

export const getBenchmarksAPI = async () => {
  try {
    let result = await axios.get(
      `${SERVER_URL}${USER_REQUEST.GET_BENCHMARKS}`,
      options()
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

export const updateBenchmarkAveragesAPI = async (data) => {
  try {
    let result = await axios.patch(
      `${SERVER_URL}${USER_REQUEST.UPDATE_BENCHMARK_AVERAGES}`,
      data,
      options()
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

export const getBenchmarkAveragesAPI = async () => {
  try {
    let result = await axios.get(
      `${SERVER_URL}${USER_REQUEST.GET_BENCHMARK_AVERAGES}`,
      options()
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

export const addBenchmarkAPI = async (raw_data) => {
  try {
    const options = {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    };
    options["headers"]["Content-Type"] = "multipart/form-data";
    const data = new FormData();
    data.set("file", raw_data.file);
    data.set("name", raw_data.name);
    let result = await axios.post(
      `${SERVER_URL}${USER_REQUEST.ADD_BENCHMARK}`,
      data,
      options
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};

export const deleteBenchmarkAPI = async (uuid) => {
  try {
    let result = await axios.delete(
      `${SERVER_URL}${USER_REQUEST.DELETE_BENCHMARK}/${uuid}`,
      options()
    );

    return result.data;
  } catch (error) {
    if (error.response === undefined) {
      return { status: 403, message: "Something Went Wrong!" };
    }
    return error.response.data;
  }
};
