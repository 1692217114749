import React from "react";
import styles from "./styles";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { navigate } from "@reach/router";

const Footer = ({ classes, showContactUs }) => {
  return (
    <Grid container justify="space-between" className={classes.footerContainer}>
      <Grid className={classes.copyrightText}>
        COPYRIGHT ©2021 BRANDCLOUD INC. ALL RIGHTS RESERVED.
      </Grid>
      {showContactUs && (
        <Grid
          className={classes.contactText}
          onClick={() => navigate("/contact-us")}
        >
          Contact Us
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(styles)(Footer);
