import React from "react";
import { navigate } from "@reach/router";
import { connect } from "react-redux";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import _ from "lodash";

// * asset
import UserImage from "../../assets/images/default.jpg";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import qIcon from "../../assets/svgs/qIcon";
// * Style
import "../../assets/style/topbar.css";

class TopBarProduct extends React.Component {
  logoutUser() {
    const role = localStorage.getItem("role");

    // Remove token and user info from the local storage
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("role");
    localStorage.removeItem("permissions");

    //navigate to respective login page
    if (role === "ADMIN") {
      navigate("/admin/login");
    } else if (role === "USER") {
      navigate("/login");
    }
  }

  getQuestionInfo(info, ref, color = "#929292") {
    return (
      <div ref="tooltip">
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip className="tooltip" id="tooltip" style={{ zIndex: 10000 }}>
              {info}
            </Tooltip>
          }
          container={this.refs[ref]}
        >
          <span
            style={{
              marginLeft: ".1rem",
            }}
          >
            {qIcon(color)}
          </span>
        </OverlayTrigger>
      </div>
    );
  }

  newGetQuestionInfo(info, ref, color = "#929292") {
    return (
      <div ref="tooltip">
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip className="tooltip" id="tooltip" style={{ zIndex: 10000 }}>
              BRANDCLOUD&#174; the only social martech platform that enables
              brands to identify, recruit, engage and monetize superfans at
              scale.
            </Tooltip>
          }
          container={this.refs["heading"]}
        >
          <span
            style={{
              marginLeft: ".1rem",
            }}
          >
            {qIcon("#929292")}
          </span>
        </OverlayTrigger>
      </div>
    );
  }

  render() {
    const { currentUser, classes } = this.props;
    const role = localStorage.getItem("role");
    return (
      <div
        className="container-fluid appbar-container"
        style={{ padding: "5px 100px" }}
      >
        <div className="row">
          <Grid
            container
            alignItems="center"
            justify="space-between"
            className={classes.appBarContainer}
          >
            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                onClick={this.props.prevScreen}
                className={classes.arrowTwo}
                src={Arrow}
              />
              <Grid
                className={classes.screenNumber}
                style={{ color: "#4D9DF8" }}
              >
                0{this.props.index}
              </Grid>
              <Grid className={classes.screenNumber}>/</Grid>
              <Grid className={classes.screenNumber}>7</Grid>
              <img
                onClick={this.props.nextScreen}
                className={classes.arrow}
                src={Arrow}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (data) => {
  return {
    currentUser: data.user.currentUser,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(TopBarProduct));
