import React, { useState } from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Modal, Typography } from "@material-ui/core";
import { Button, Container } from "@material-ui/core";
import Arrow from "../../assets/images/right_arrow.png";
import Dashboard from "../../assets/images/Dashboard7.png";
import { Link } from "@reach/router";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import circle from "../../assets/images/circle.png";
import db3 from "../../assets/images/db3.png";
import db1 from "../../assets/images/db1.png";
import db2 from "../../assets/images/db2.png";

const ProductPreviewSeven = (props) => {
  const { classes, onClick } = props;
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Container style={{ maxWidth: "1400px", postion: "relative" }}>
      <div
        onClick={onClick}
        style={{
          position: "absolute",
          right: 46,
          top: "14%",
          textDecoration: "underline",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          borderRadius: "10px",
        }}
      >
        <Typography
          varient={2}
          style={{
            fontFamily: "Poppins-Regular",
            fontSize: "1.3rem",
            fontWeight: 400,
          }}
        >
          Exit
        </Typography>
      </div>
      <img className={classes.circle} src={circle} alt="img" />
      <Grid className={classes.logoLabel}>
        <Grid>Full BrandCloud</Grid>
        <Grid>Dashboard</Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <Grid
            container
            direction="column"
            justify="space-around"
            style={{ height: "85%" }}
          >
            <Grid style={{ display: "flex", alignItems: "center" }}>
              <Grid container direction="row" justify="center">
                <img src={db3} style={{ width: "50%" }} />
              </Grid>
              <Grid>
                <img src={Arrow} className={classes.arrow} />
              </Grid>
            </Grid>
            <Grid style={{ display: "flex", alignItems: "center" }}>
              <Grid container direction="row" justify="center">
                <img src={db1} style={{ width: "50%" }} />
              </Grid>
              <Grid>
                <img src={Arrow} className={classes.arrow} />
              </Grid>
            </Grid>
            <Grid style={{ display: "flex", alignItems: "center" }}>
              <Grid container direction="row" justify="center">
                <img src={db2} style={{ width: "50%" }} />
              </Grid>
              <Grid>
                <img src={Arrow} className={classes.arrow} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={7} style={{ padding: "10px" }}>
          <Grid onClick={openModal}>
            <img src={Dashboard} className={classes.dashbaordImage} />
          </Grid>
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid>
            <Button
              variant="contained"
              style={{ color: "white" }}
              color="secondary"
              className={classes.GotoButton}
              component={Link}
              to="/dashboard"
            >
              Go to the Dashboard
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justify="flex-start"
        align="space-between"
        style={{ padding: "26px 0" }}
      >
        <Button
          variant="contained"
          style={{ color: "white" }}
          color="secondary"
          className={classes.homeButton}
          component={Link}
          to="/"
        >
          Home
        </Button>
        <Button
          variant="contained"
          style={{ color: "white" }}
          color="secondary"
          className={classes.feedbackButton}
          component={Link}
          to="/contact-us"
        >
          Feedback
        </Button>
      </Grid>
      <Modal open={open} onClose={closeModal}>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{
            padding: "50px 0",
            overflowY: "auto",
            height: "100%",
            alignItems: "flex-start",
            flexDirection: "row-reverse",
          }}
        >
          <IconButton
            aria-label="close"
            style={{ color: "white", marginLeft: "1rem" }}
            onClick={closeModal}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
          <img src={Dashboard} className={classes.modalDashbaordImage} />
        </Grid>
      </Modal>
    </Container>
  );
};

export default withStyles(styles)(ProductPreviewSeven);
