import React, { useState } from "react";
import ProductPreview from "../../components/ProductPreview";
import ProductPreviewTwo from "../../components/ProductPreviewTwo";
import ProductPreviewThree from "../../components/ProductPreviewThree";
import ProductPreviewFour from "../../components/ProductPreviewFour";
import ProductPreviewFive from "../../components/ProductPreviewFive";
import ProductPreviewSix from "../../components/ProductPreviewSix";
import ProductPreviewSeven from "../../components/ProductPreviewSeven";
import TopBarProduct from "../../components/TopBarProduct";
import { useNavigate } from "@reach/router";
import landingPage from "../LandingPage/landing-page";

const ProductPreviewScreen = () => {
  const [index, setIndex] = useState(0);

  const nextScreen = () => {
    if (index < 7) setIndex(index + 1);
  };

  const prevScreen = () => {
    if (index > 0) setIndex(index - 1);
  };
  const navigate = useNavigate();
  const handleClick = () => {
    const role = localStorage.getItem("role");
    role === "ADMIN" ? navigate("/dashboard/requests") : navigate("/dashboard");
  };

  return (
    <div>
      <TopBarProduct
        index={index + 1}
        nextScreen={nextScreen}
        prevScreen={prevScreen}
      />
      {index == 0 && (
        <ProductPreview nextScreen={nextScreen} onClick={handleClick} />
      )}
      {index == 1 && (
        <ProductPreviewTwo nextScreen={nextScreen} onClick={handleClick} />
      )}
      {index == 2 && (
        <ProductPreviewThree nextScreen={nextScreen} onClick={handleClick} />
      )}
      {index == 3 && (
        <ProductPreviewFour nextScreen={nextScreen} onClick={handleClick} />
      )}
      {index == 4 && (
        <ProductPreviewFive nextScreen={nextScreen} onClick={handleClick} />
      )}
      {index == 5 && (
        <ProductPreviewSix nextScreen={nextScreen} onClick={handleClick} />
      )}
      {index == 6 && (
        <ProductPreviewSeven nextScreen={nextScreen} onClick={handleClick} />
      )}
      {index == 7 ? () => navigate("/") : null}
    </div>
  );
};

export default ProductPreviewScreen;
