import React from "react";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { Container, Button } from "@material-ui/core";
import Badge from "../../assets/images/badge.png";
import Shape from "../../assets/images/shape.png";
import { Link } from "@reach/router";
import qIcon from "../../assets/svgs/qIcon";
import Tooltip from "@material-ui/core/Tooltip";
import circle from "../../assets/images/circle.png";
import Rect from "../../assets/images/Rect.png";
import d5 from "../../assets/images/d5.png";
import d4 from "../../assets/images/d4.png";
import moreInfo from "../../assets/images/moreInfo.png";

const ProductPreviewFour = (props) => {
  const { classes, nextScreen, onClick } = props;
  return (
    <Container
      style={{ maxWidth: "1400px", postion: "relative", height: "70vh" }}
    >
      <img className={classes.circle} src={circle} alt="img" />
      <img className={classes.rect} src={Rect} alt="img" />
      <div
        onClick={onClick}
        style={{
          position: "absolute",
          right: 46,
          top: "14%",
          textDecoration: "underline",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          borderRadius: "10px",
        }}
      >
        <Typography
          varient={2}
          style={{
            fontFamily: "Poppins-Regular",
            fontSize: "1.3rem",
            fontWeight: 400,
          }}
        >
          Exit
        </Typography>
      </div>
      <Grid container direction="row" justify="center">
        <img className={classes.cloud2} src={d5} />
      </Grid>

      <Grid style={{ padding: "10px 0" }}>
        <Grid className={classes.previewDetail}>
          Monetize your superfan FORCE using trackable brand
        </Grid>
        <Grid className={classes.previewDetail}>
          advocacy MISSIONS tied to key brand marketing
        </Grid>
        <Grid className={classes.previewDetail}>objectives.</Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-between"
        style={{ padding: "15px 0 0" }}
      >
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            justify="center"
            direction="column"
            container
            style={{ padding: "26px 10px" }}
          >
            <Grid className={classes.header} style={{ position: "relative" }}>
              MISSION
              <Tooltip title="Add title" interactive>
                <span className={classes.absolute}>
                  {qIcon("rgb(146, 146, 146)")}
                </span>
              </Tooltip>
            </Grid>
            <Grid className={classes.slogan}>
              Superfan mission tied to brand objectives
            </Grid>
          </Grid>
        </Grid>
        <Grid
          justify="center"
          alignItems="center"
          style={{ display: "flex" }}
        ></Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={Badge} width="100px" className={classes.iconLogos1} />
          <Grid
            justify="space-evenly"
            direction="column"
            container
            style={{ padding: "26px 16px" }}
          >
            <Grid className={classes.header} style={{ position: "relative" }}>
              CUSTOMIZE
              <Tooltip title="Add title" interactive>
                <span className={classes.absolute}>
                  {qIcon("rgb(146, 146, 146)")}
                </span>
              </Tooltip>
            </Grid>
            <Grid className={classes.slogan}>With Brand message and offer</Grid>
          </Grid>
        </Grid>
        <Grid
          justify="center"
          alignItems="center"
          style={{ display: "flex" }}
        ></Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            justify="center"
            direction="column"
            container
            style={{ padding: "26px 18px" }}
          >
            <Grid className={classes.header} style={{ position: "relative" }}>
              SOCIALIZE
              <Tooltip title="Add title" interactive>
                <span className={classes.absolute}>
                  {qIcon("rgb(146, 146, 146)")}
                </span>
              </Tooltip>
            </Grid>
            <Grid className={classes.slogan}>
              To convert friends and family to purchase
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.smallLogo}>
          <img className={classes.cloud3} src={d4} />
          <Grid style={{ paddingTop: "10px" }}>
            <img
              style={{
                padding: "0rem 2rem",
                marginBottom: ".3rem",
                width: "100%",
              }}
              src={moreInfo}
              onClick={nextScreen}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justify="flex-start"
        align="space-between"
        style={{ padding: "10px 0 0" }}
      >
        <Button
          variant="contained"
          style={{ color: "white" }}
          color="secondary"
          className={classes.homeButton}
          component={Link}
          to="/"
        >
          Home
        </Button>
        <Button
          variant="contained"
          style={{ color: "white" }}
          color="secondary"
          className={classes.feedbackButton}
          component={Link}
          to="/contact-us"
        >
          Feedback
        </Button>
      </Grid>
    </Container>
  );
};

export default withStyles(styles)(ProductPreviewFour);
