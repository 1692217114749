const styles = (theme) => ({
  circle: {
    position: "absolute",
    zIndex: "0",
    right: "21%",
    top: "9%",
    width: "115px",
  },
  rect: {
    position: "absolute",
    zIndex: "-2",
    width: "200px",
    left: "40%",
    bottom: "0%",
  },
  previewTitle: {
    textAlign: "center",
    fontSize: "  calc(1.7 * 1rem)",
    fontFamily: "Poppins-Bold",
    [theme.breakpoints.only("xl")]: {
      fontSize: "  calc(2.2 * 1rem)",
    },
  },

  previewDetail: {
    fontSize: " calc(1 * 1rem)",
    textAlign: "center",
    fontFamily: "Poppins-SemiBold",
  },
  previewTitles: {
    textAlign: "center",
    fontSize: "  calc(1.6 * 1rem)",
    fontFamily: "Poppins-Bold",
  },

  logoContainer: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    padding: "10px",
  },

  superfan: {
    textAlign: "center",
    fontFamily: "Poppins-SemiBold",
    fontSize: "18px",
  },
  absolute: {
    position: "absolute",
    top: -10,
    right: 6,
  },
  absolute1: {
    position: "absolute",
    top: -10,
    right: 15,
  },
  logoHeader: {
    textAlign: "center",
    fontSize: "30px",
    fontFamily: "Poppins-Bold",
  },

  labelLogo: {
    textAlign: "center",
    fontFamily: "Poppins-SemiBold",
    fontSize: "15px",
    color: "#3CB4E4",
    padding: "15px",
  },

  smallLogo: {
    textAlign: "center",
    marginTop: "-60px",
    [theme.breakpoints.down("md")]: {
      marginTop: "0",
    },
  },

  iconLogos: {
    height: "10em",
    [theme.breakpoints.down("md")]: {
      height: "6em",
    },
  },

  iconLogoSmall: {
    height: "8em",
    [theme.breakpoints.down("md")]: {
      height: "4em",
    },
  },

  iconTrack: {
    height: "7em",
    marginTop: "40px",
    [theme.breakpoints.down("md")]: {
      height: "5em",
    },
  },

  arrow: {
    height: "3.6em",
    [theme.breakpoints.down("md")]: {
      height: "2em",
    },
  },

  header: {
    fontFamily: "Poppins-SemiBold",
    fontSize: "26px",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
    },
  },

  slogan: {
    fontFamily: "Poppins-SemiBold",
    fontSize: "18px",
    width: "150px",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
    },
  },

  backgroundImage: {
    width: "100%",
    height: "90%",
    objectFit: "contain",
    position: "absolute",
    left: "0px",
    top: "85px",
    zIndex: "-1",
  },

  homeButton: {
    background: "#FB260E",
    fontSize: "24px",
    fontFamily: "Poppins-Medium",
    textTransform: "capitalize",
    padding: " 5px 59px",
    marginRight: "1rem",
  },

  feedbackButton: {
    background: "#4D9DF8",
    fontSize: "24px",
    fontFamily: "Poppins-Medium",
    textTransform: "capitalize",
    padding: " 5px 59px",
  },

  moreInfoButton: {
    background: "#4D9DF8",
    fontSize: "18px",
    fontFamily: "Poppins-Medium",
    textTransform: "capitalize",
    padding: "10px 40px",
  },
  cloud: {
    padding: "0rem 2rem",
    marginBottom: ".3rem",
    width: "15rem",
    maxWidth: "20rem",
    [theme.breakpoints.only("xl")]: {
      width: "19rem",
    },
  },
  cloud2: {
    padding: "0rem 2rem",
    marginBottom: ".3rem",
    width: "14rem",
    [theme.breakpoints.only("xl")]: {
      width: "19rem",
    },
  },
  cloud3: {
    padding: "0rem 2.2rem",
    marginBottom: ".3rem",
    width: "12rem",
    [theme.breakpoints.only("xl")]: {
      width: "19rem",
    },
  },
});

export default styles;
