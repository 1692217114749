import React from "react";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Tabsec from "./tabSec";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
  buttonCollapse: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    margin: "10px 0px",
    boxShadow: "none",
  },
  Menu: {
    marginTop: "2rem",
    backgroundColor: "rgba(255,255,255,.5)",
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
    textAlign: "center",
    flexDirection: "column",
  },
});

class ButtonAppBarCollapse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      modalVisible: false,
    };
    this.handleMenu = this.handleMenu.bind(this);
  }
  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div className={classes.buttonCollapse}>
        {open === false ? (
          <IconButton onClick={this.handleMenu}>
            <MenuIcon size="small" />
          </IconButton>
        ) : (
          <IconButton
            onClick={this.handleClose}
            aria-label="close"
            style={{ color: "black", marginLeft: "1rem" }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        )}
        <Tabsec
          anchorEl={anchorEl}
          modalVisible={open}
          setModalVisible={this.handleClose}
        >
          {this.props.children}
        </Tabsec>
      </div>
    );
  }
}
export default withStyles(styles)(ButtonAppBarCollapse);
